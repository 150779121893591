.App {
  text-align: center;
}

.App-header {
  background-image: url('./image1.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  margin: 0;
  padding: 20px;
} 